/* COLOR GRID */

#color-palette .grid-wrapper {
	display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: .5em;
  padding: 0;
	width: 100%;
	max-width: 100%;
}

#color-palette .grid-wrapper .grid-item {
	box-sizing: border-box;
	margin: 0;
	padding: .5em;
	width: auto;
	height: 100%;
}

#color-palette span {
	display: block;
	box-sizing: border-box;
	margin: 0 0 .33em;
	padding: 0;
	width: 100%;
	font-family: 'Antenna', sans-serif;
	color: $white;
	font-size: clamp(#{$font-step-075}, #{$font-step-075} + 3.5vw, #{$font-step-075}) !important;
	line-height: clamp(#{$line-step-1-25}, #{$line-step-1-25} + 3.5vw, #{$line-step-1-25});
	line-height: 1.35;
	text-transform: uppercase;
}

/* END CSS */
