/* VARIABLES - BACKGROUND-COLOR */

/* WHITE - BACKGROUND */
.bg-white { background-color: $white; }
.bg-blue { background-color: $blue; }

/* OVERLAY - BACKGROUND */
.bg-overlay { background-color: $bg-overlay; }
.bg-overlay-dark { background-color: $bg-overlay-dark; }
.bg-overlay-extra-dark { background-color: $bg-overlay-extra-dark; }
.bg-overlay-light { background-color: $bg-overlay-light; }
.bg-overlay-extra-light { background-color: $bg-overlay-extra-light; }

/* MAROON - BACKGROUND */
.maroon { background-color: $maroon; }
.maroon-dark { background-color: $maroon-dark; }
.maroon-extra-dark { background-color: $maroon-extra-dark; }
.maroon-light { background-color: $maroon-light; }
.maroon-extra-light { background-color: $maroon-extra-light; }

/* BRICK - BACKGROUND */
.brick { background-color: $brick; }
.brick-dark { background-color: $brick-dark; }
.brick-extra-dark { background-color: $brick-extra-dark; }
.brick-light { background-color: $brick-light; }
.brick-extra-light { background-color: $brick-extra-light; }

/* GOLD - BACKGROUND */
.gold	{ background-color: $gold; }
.gold-dark	{ background-color: $gold-dark; }
.gold-extra-dark	{ background-color: $gold-extra-dark; }
.gold-light	{ background-color: $gold-light; }

/* GOLD - PREMIERE - BACKGROUND */
.gold-premiere	{ background-color: $gold-premiere; }
.gold-premiere-dark	{ background-color: $gold-premiere-dark; }
.gold-premiere-extra-dark	{ background-color: $gold-premiere-extra-dark; }
.gold-premiere-light	{ background-color: $gold-premiere-light; }
.gold-premiere-extra-light	{ background-color: $gold-premiere-extra-light; }

/* PATINA - BACKGROUND */
.patina { background-color: $patina; }
.patina-dark { background-color: $patina-dark; }
.patina-extra-dark { background-color: $patina-extra-dark; }
.patina-light { background-color: $patina-light; }
.patina-extra-light { background-color: $patina-extra-light; }

/* TERRACOTTA - BACKGROUND */
.terracotta { background-color: $terracotta; }
.terracotta-dark { background-color: $terracotta-dark; }
.terracotta-extra-dark { background-color: $terracotta-extra-dark; }
.terracotta-light { background-color: $terracotta-light; }
.terracotta-extra-light { background-color: $terracotta-extra-light; }

/* OPUS - BACKGROUND */
.opus { background-color: $opus; }
.opus-dark { background-color: $opus-dark; }
.opus-extra-dark { background-color: $opus-extra-dark; }
.opus-light { background-color: $opus-light; }
.opus-extra-light { background-color: $opus-extra-light; }

/* PINE - BACKGROUND */
.pine { background-color: $pine; }
.pine-dark { background-color: $pine-dark; }
.pine-extra-dark { background-color: $pine-extra-dark; }
.pine-light { background-color: $pine-light; }
.pine-extra-light { background-color: $pine-extra-light; }

/* WATER - BACKGROUND */
.water { background-color: $water; }
.water-dark { background-color: $water-dark; }
.water-extra-dark { background-color: $water-extra-dark; }
.water-light { background-color: $water-light; }
.water-extra-light { background-color: $water-extra-light; }

/* GRAY  - BACKGROUND */
.gray { background-color: $gray; }
.gray-dark { background-color: $gray-dark; }
.gray-extra-dark { background-color: $gray-extra-dark; }
.gray-light { background-color: $gray-light; }
.gray-extra-light { background-color: $gray-extra-light; }

/* COPPER - BACKGROUND */
.copper { background-color: $copper; }
.copper-dark { background-color: $copper-dark; }
.copper-extra-dark { background-color: $copper-extra-dark; }
.copper-light { background-color: $copper-light; }
.copper-extra-light { background-color: $copper-extra-light; }

/* VARIABLES - FONT COLOR */

/* MAROON - COLOR */
.text-maroon { color: $maroon; }
.text-maroon-dark { color: $maroon-dark; }
.text-maroon-extra-dark { color: $maroon-extra-dark; }
.text-maroon-light { color: $maroon-light; }
.text-maroon-extra-light { color: $maroon-extra-light; }

/* GOLD - COLOR */
.text-gold	{ color: $gold; }
.text-gold-dark	{ color: $gold-dark; }
.text-gold-extra-dark	{ color: $gold-extra-dark; }
.text-gold-light	{ color: $gold-light; }

/* PATINA - COLOR */
.text-patina { color: $patina; }
.text-patina-dark { color: $patina-dark; }
.text-patina-extra-dark { color: $patina-extra-dark; }
.text-patina-light { color: $patina-light; }
.text-patina-extra-light { color: $patina-extra-light; }

/* TERRACOTTA - COLOR */
.text-terracotta { color: $terracotta; }
.text-terracotta-dark { color: $terracotta-dark; }
.text-terracotta-extra-dark { color: $terracotta-extra-dark; }
.text-terracotta-light { color: $terracotta-light; }
.text-terracotta-extra-light { color: $terracotta-extra-light; }

/* GRAY - COLOR */
.text-gray { color: $gray; }
.text-gray-dark { color: $gray-dark; }
.text-gray-extra-dark { color: $gray-extra-dark; }
.text-gray-light { color: $gray-light; }
.text-gray-extra-light { color: $gray-extra-light; }

/* COPPER - COLOR */
.text-copper { color: $copper; }
.text-copper-dark { color: $copper-dark; }
.text-copper-extra-dark { color: $copper-extra-dark; }
.text-copper-light { color: $copper-light; }
.text-copper-extra-light { color: $copper-extra-light; }

/* END - COLOR */
